import {
    BaseMetaAttributeValue,
    LinkedObjectsAttributeValue,
} from '@datagalaxy/meta-attribute-domain';

export function buildLinkedObjectsValue(
    baseMetaAttributeValue: BaseMetaAttributeValue,
    value: unknown,
): LinkedObjectsAttributeValue {
    return {
        ...baseMetaAttributeValue,
        linkedObjectIds: getLinkedObjectIdsFromValue(value),
    };
}

function getLinkedObjectIdsFromValue(value: unknown): string[] {
    if (Array.isArray(value)) {
        return value.map(mapLinkToObjectId).filter((x) => !!x) as string[];
    }
    const id = mapLinkToObjectId(value);
    return id ? [id] : [];
}

function mapLinkToObjectId(link: unknown) {
    if (!link || typeof link !== 'object') {
        return undefined;
    }
    const typedLink = link as {
        LinkedData: { Data: { DataReferenceId: string } };
    };
    return typedLink.LinkedData.Data.DataReferenceId;
}
