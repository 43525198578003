import {
    APP_INITIALIZER,
    ApplicationConfig,
    EnvironmentProviders,
    ErrorHandler,
    importProvidersFrom,
    Injector,
    Provider,
} from '@angular/core';
import { appRoutes } from './app.routes';
import { AppDataService } from './services/app-data.service';
import { AuthV2TempAppDataService } from './services/auth-v2-temp-app-data-service';
import { API_BASE_URL } from './core/api/api-config';
import { apiBaseUrlFactory } from './core/api/api-base-url-factory';
import { APP_CONFIG, AppConfigService } from '@datagalaxy/webclient/config';
import {
    provideRouter,
    withComponentInputBinding,
    withEnabledBlockingInitialNavigation,
    withRouterConfig,
} from '@angular/router';
import { ServerTimeService } from './services/serverTime.service';
import {
    FUNCTIONAL_LOG_CONTEXT,
    MonitoringApiService,
} from '@datagalaxy/shared/monitoring/data-access';
import {
    APP_BASE_HREF,
    LocationStrategy,
    PathLocationStrategy,
} from '@angular/common';
import {
    FullscreenOverlayContainer,
    OverlayContainer,
} from '@angular/cdk/overlay';
import { GlobalErrorHandler } from './core/global-error-handler';
import { RICH_TEXT_DEFAULTS } from '@datagalaxy/core-ui';
import { ClientErrorService } from './services/clientError.service';
import { translateConfigFactory } from './core/translate/translation.factory';
import {
    BACKEND_API_CONFIG,
    BackendApiModule,
    RestApiModule,
} from '@datagalaxy/data-access';
import {
    AuthenticationModule,
    AuthenticationService,
} from '@datagalaxy/webclient/auth/feature';
import { backendApiFactory } from './core/api/backend-api.factory';
import { UserPublicService } from '@datagalaxy/webclient/user/ui';
import { UserService } from './services/user.service';
import { DATA_TEST_ID_CONFIG } from '@datagalaxy/ui/testing';
import { QuillContentAdapter } from './shared/richText/QuillContentAdapter';
import { backendHttpInterceptors } from './core/api/backend-http-interceptors';
import { CookieService } from 'ngx-cookie-service';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ConnectivityApiModule } from '@datagalaxy/webclient/connectivity/data-access';
import { DataQualityApiModule } from '@datagalaxy/webclient/data-quality/data-access';
import { DxyDialogModule } from '@datagalaxy/core-ui/dialog';
import { MultilingualFeatureModule } from '@datagalaxy/webclient/multilingual/feature';
import { ATTRIBUTES_FINDER_TOKEN } from '@datagalaxy/webclient/attribute/feature';
import { AttributeFinderService } from './shared/attribute/attribute-finder.service';
import { COMMENTARY_MODAL_SERVICE_TOKEN } from '@datagalaxy/webclient/comment/feature';
import { CommentaryService } from './commentary/commentary.service';
import {
    DXY_TRANSLATE_CONFIG,
    LanguageService,
    provideTranslation,
} from '@datagalaxy/translate';
import { DXY_IMAGE_LANGUAGE } from '@datagalaxy/core-ui/image-input';
import { ClientContextUtil } from './shared/util/ClientContextUtil';
import { provideToastr } from 'ngx-toastr';
import { DxyLegacyToastComponent } from '@datagalaxy/ui/toaster';
import {
    CHATBOT_CURRENT_USER_RESOLVER,
    CHATBOT_CURRENT_WORKSPACE_RESOLVER,
    CurrentUserResolver,
    CurrentWorkspaceResolver,
} from '@datagalaxy/chatbot-feature';
import { WorkspaceStore } from '@datagalaxy/webclient/workspace/data-access';
import { CurrentUserService } from '@datagalaxy/webclient/user/data-access';
import { TeamService } from './team/team.service';
import { TeamPublicService } from '@datagalaxy/webclient/team/data-access';

const providers: (Provider | EnvironmentProviders)[] = [
    {
        provide: API_BASE_URL,
        useFactory: apiBaseUrlFactory,
        deps: [AppConfigService],
    },
    {
        provide: AppDataService,
        useClass: AuthV2TempAppDataService,
    },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
        provide: APP_INITIALIZER,
        multi: true,
        useFactory:
            (
                monitoringApiService: MonitoringApiService,
                serverTimeService: ServerTimeService,
            ) =>
            async () => {
                const res = await monitoringApiService.getServerTime();
                serverTimeService.initTimeReference(res);
            },
        deps: [MonitoringApiService, ServerTimeService],
    },
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: Window, useValue: window },
    // Enable a modal (mat-dialog) to be visible when an element is displayed full-screen.
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
        provide: BACKEND_API_CONFIG,
        deps: [
            AppConfigService,
            AppDataService,
            ServerTimeService,
            AuthenticationService,
            API_BASE_URL,
        ],
        useFactory: backendApiFactory,
    },
    { provide: UserPublicService, useExisting: UserService },
    { provide: TeamPublicService, useExisting: TeamService },
    {
        provide: DATA_TEST_ID_CONFIG,
        deps: [AppConfigService],
        useFactory: (appConfigService: AppConfigService) => ({
            disabled: appConfigService.DISABLE_DATA_TEST_IDS,
        }),
    },
    {
        provide: FUNCTIONAL_LOG_CONTEXT,
        useFactory: (injector: Injector) => () =>
            ClientContextUtil.getClientContext(injector),
        deps: [Injector],
    },
    {
        provide: RICH_TEXT_DEFAULTS,
        useValue: {
            quillContentAdapter: new QuillContentAdapter(),
        },
    },
    {
        provide: DXY_IMAGE_LANGUAGE,
        useFactory: (languageService: LanguageService) =>
            languageService.getLanguage(),
        deps: [LanguageService],
    },
    {
        provide: ATTRIBUTES_FINDER_TOKEN,
        useClass: AttributeFinderService,
    },
    ...backendHttpInterceptors,
    CookieService, // To delete after AuthV2 feature flag cleanup
    provideToastr({
        toastComponent: DxyLegacyToastComponent,
        preventDuplicates: true,
        resetTimeoutOnDuplicate: true,
    }),
    importProvidersFrom(
        BackendApiModule,
        RestApiModule,
        ConnectivityApiModule,
        DataQualityApiModule,
        AuthenticationModule,
        DxyDialogModule,
        //#region feature modules
        MultilingualFeatureModule.forRoot(),
        //#region
    ),
    {
        provide: DXY_TRANSLATE_CONFIG,
        deps: [APP_CONFIG, ClientErrorService],
        useFactory: translateConfigFactory,
    },
    provideTranslation({
        languages: ['fr', 'en', 'nl', 'es', 'it', 'de', 'pt'],
        defaultLanguage: 'en',
    }),
    provideAnimations(),
    provideRouter(
        appRoutes,
        withComponentInputBinding(),
        withEnabledBlockingInitialNavigation(),
        withRouterConfig({ paramsInheritanceStrategy: 'always' }),
    ),
    {
        provide: COMMENTARY_MODAL_SERVICE_TOKEN,
        useClass: CommentaryService,
    },
    {
        provide: CHATBOT_CURRENT_WORKSPACE_RESOLVER,
        deps: [WorkspaceStore],
        useFactory: (workspaceStore: WorkspaceStore) =>
            workspaceStore as CurrentWorkspaceResolver,
    },
    {
        provide: CHATBOT_CURRENT_USER_RESOLVER,
        deps: [CurrentUserService],
        useFactory: (currentUserService: CurrentUserService) =>
            currentUserService as CurrentUserResolver,
    },
];

export const getAppConfig = (): ApplicationConfig => ({
    providers,
});
