import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class TechnologyUrlService {
    public getImageUrl(hash: string): string {
        return !hash
            ? ''
            : `api/ClientAdmin/GetImage?hash=${encodeURIComponent(hash)}`;
    }
}
