import {
    BaseMetaAttributeValue,
    HyperlinkAttributeValue,
} from '@datagalaxy/meta-attribute-domain';

export function buildHyperlinkValue(
    baseMetaAttributeValue: BaseMetaAttributeValue,
    value: string | undefined,
): HyperlinkAttributeValue {
    const hyperLinkObject = deserializeHyperlink(value);
    return {
        ...baseMetaAttributeValue,
        value: hyperLinkObject?.Url ?? '',
        displayName: hyperLinkObject?.Name,
    };
}

function deserializeHyperlink(serializedValue: string | undefined) {
    if (!serializedValue) {
        return undefined;
    }
    return JSON.parse(serializedValue) as {
        Name: string;
        Url: string;
    };
}

export function serializeHyperlink(value: HyperlinkAttributeValue): string {
    if (!value) {
        return '';
    }
    return JSON.stringify({
        Name: value.displayName,
        Url: value.value,
    });
}
