import {
    computed,
    Directive,
    effect,
    ElementRef,
    HostListener,
    inject,
    input,
    OnInit,
    output,
} from '@angular/core';
import { MenuComponent } from './menu/menu.component';
import { MenuAlignment } from './menu/menu-alignment';
import { MenuOpener } from './menu-opener';

@Directive({
    selector: '[dxyMenuTrigger]',
    standalone: true,
    providers: [MenuOpener],
})
export class MenuTriggerDirective implements OnInit {
    public menu = input.required<MenuComponent>({
        alias: 'dxyMenuTrigger',
    });
    public triggerEvent = input<'click' | 'focus'>('click');
    public alignment = input<MenuAlignment>('left');
    public alignMenuTo = input<ElementRef<HTMLElement>>();
    public noBackdrop = input<boolean>(false);
    public preventCloseOnClick = input<boolean>(false);
    public disableMenu = input<boolean>(false);
    public menuClose = output<boolean>();

    private alignElementRef = computed(() => {
        return this.alignMenuTo() ?? this.elementRef;
    });

    private menuOpener = inject(MenuOpener);
    private elementRef = inject(ElementRef);

    public isMenuOpen = this.menuOpener.isOpen;

    private emitMenuCloseEffect = effect(() => {
        if (!this.isMenuOpen()) {
            this.menuClose.emit(true);
        }
    });

    public ngOnInit() {
        this.menu().close.subscribe(() => {
            this.closeMenu();
        });
    }

    @HostListener('click') private toggleMenuOnClick() {
        if (this.triggerEvent() != 'click') {
            return;
        }
        if (this.preventCloseOnClick()) {
            this.openMenu();
            return;
        }
        this.toggleMenu();
    }

    @HostListener('focus') private openMenuOnFocus() {
        if (this.triggerEvent() != 'focus') {
            return;
        }
        if (!this.menuOpener.isOpen()) {
            this.openMenu();
        }
    }

    @HostListener('blur', ['$event']) private closeMenuOnFocus(
        event: FocusEvent,
    ) {
        if (this.triggerEvent() != 'focus' || this.isClickInsideMenu(event)) {
            return;
        }
        this.closeMenu();
    }

    @HostListener('document:click', ['$event'])
    private closeOnClickOutsideMenuOrTriggerElement(event: MouseEvent) {
        if (this.triggerEvent() != 'click') {
            return;
        }
        event.stopPropagation();
        if (this.isClickOutside(event)) {
            this.closeMenu();
        }
    }

    public isClickOutside(event: MouseEvent): boolean {
        return (
            this.noBackdrop() &&
            !this.elementRef.nativeElement.contains(event.target) &&
            !this.menuOpener
                .overlayElement()
                ?.contains(event.target as HTMLElement) &&
            this.menu()
                .childTriggers()
                .every((t) => t.isClickOutside(event))
        );
    }

    public closeMenu() {
        this.menuOpener.close();
    }
    public openMenu() {
        if (this.disableMenu()) {
            return;
        }
        this.menuOpener.open({
            menuTemplate: this.menu().menuTemplate(),
            alignment: this.alignment(),
            hasBackdrop: !this.noBackdrop(),
            alignElementRef: this.alignElementRef(),
        });
    }

    public refreshMenuPosition() {
        this.menuOpener.refreshPosition();
    }

    private isClickInsideMenu(event: FocusEvent | MouseEvent): boolean {
        const relatedTarget = event.relatedTarget as HTMLElement;
        return !!(
            relatedTarget &&
            this.menuOpener.overlayElement()?.contains(relatedTarget)
        );
    }

    private toggleMenu() {
        if (this.menuOpener.isOpen()) {
            this.closeMenu();
        } else {
            this.openMenu();
        }
    }
}
