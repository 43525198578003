import { inject, Injectable } from '@angular/core';
import { AttributeMetaInfoStore } from '@datagalaxy/meta-attribute-data-access';
import {
    HierarchyElement,
    MetaAttributeValue,
} from '@datagalaxy/meta-attribute-domain';
import {
    MetaObject,
    MetaObjectAccessRights,
    MetaObjectType,
} from '@datagalaxy/meta-object-domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { buildMetaAttributeValue } from './build-meta-attribute-value';
import {
    HierarchicalData,
    HierarchyDataDescriptor,
    ServerType,
} from '@datagalaxy/dg-object-model';

@Injectable({ providedIn: 'root' })
export class EntityItemAdapter {
    private amiStore = inject(AttributeMetaInfoStore);

    public buildMetaObjectFromEntityItem(entityItem: EntityItem): MetaObject {
        return {
            id: entityItem.ReferenceId,
            versionId: entityItem.VersionId,
            type: this.getMetaObjectType(entityItem),
            attributeValues: this.buildMetaAttributeValues(entityItem),
            childrenCount:
                ((entityItem.Attributes as Record<string, unknown>)[
                    'LogicalChildrenCount'
                ] as number) ?? 0,
            displayName: entityItem.DisplayName,
            technicalName: entityItem.TechnicalName,
            hierarchy: this.buildHierarchy(entityItem),
            technologyCode: this.buildTechnologyCode(entityItem),
            accessRights: this.buildAccessRights(entityItem),
        };
    }

    public buildMetaObjectFromHierarchicalData(
        hierarchicalData: HierarchicalData,
    ): MetaObject {
        return {
            id: hierarchicalData.ReferenceId,
            versionId: hierarchicalData.VersionId,
            type: this.getMetaObjectType(hierarchicalData),
            attributeValues: [],
            technologyCode: hierarchicalData.TechnologyCode,
            displayName: hierarchicalData.DisplayName,
            technicalName: hierarchicalData.TechnicalName,
        };
    }

    private buildMetaAttributeValues(entity: EntityItem): MetaAttributeValue[] {
        const attributeValues = entity.Attributes as Record<string, unknown>;
        const metaAttributeValues: MetaAttributeValue[] = [];
        const amis = entity.Meta?.Attributes ?? [];

        attributeValues['DataTypePrecisionSize'] = attributeValues[
            'DataTypeRef'
        ] as string;

        for (const key of Object.keys(attributeValues)) {
            const value = attributeValues[key];
            const ami =
                amis.find((a) => a.AttributeKey === key) ??
                this.amiStore.getAttributeMetaInfo(
                    key,
                    ServerType[entity.ServerType],
                );

            if (!ami) {
                continue;
            }
            const metaAttributeValue = buildMetaAttributeValue(
                entity,
                ami,
                value,
            );
            if (metaAttributeValue) {
                metaAttributeValues.push(metaAttributeValue);
            }
        }
        return metaAttributeValues;
    }

    private getMetaObjectType(
        entityItem: EntityItem | HierarchicalData,
    ): MetaObjectType {
        switch (entityItem.DataTypeName) {
            case 'Model':
                return `${entityItem.SubTypeName}Model` as MetaObjectType;
            default:
                return entityItem.SubTypeName as MetaObjectType;
        }
    }

    private buildHierarchy(entityItem: EntityItem) {
        return (
            entityItem.HddData?.Parents?.map((h) => {
                const hierarchyElement: HierarchyElement =
                    h.DataTypeName === 'Project'
                        ? this.buildWorkspaceHierarchyElement(h)
                        : this.buildMetaObjectHierarchyElement(entityItem, h);
                return hierarchyElement;
            }).reverse() ?? []
        );
    }

    private buildTechnologyCode(entityItem: EntityItem): string {
        const technologyCode = entityItem.HddData.TechnologyCode;

        if (technologyCode) {
            return technologyCode;
        }

        return entityItem.getAttributeValue<string>('TechnologyCode');
    }

    private buildMetaObjectHierarchyElement(
        entityItem: EntityItem,
        hdd: HierarchyDataDescriptor,
    ): HierarchyElement {
        return {
            id: hdd.DataReferenceId,
            displayName: hdd.DisplayName,
            type: 'meta-object',
            versionId: entityItem.VersionId,
            technicalName: hdd.IsTechnical
                ? entityItem.TechnicalName
                : undefined,
        };
    }

    private buildWorkspaceHierarchyElement(
        hdd: HierarchyDataDescriptor,
    ): HierarchyElement {
        return {
            id: hdd.DataReferenceId,
            displayName: hdd.DisplayName,
            type: 'workspace',
        };
    }

    private buildAccessRights(entityItem: EntityItem): MetaObjectAccessRights {
        return {
            administrator: entityItem.SecurityData.HasAdministratorAccess,
            read: entityItem.SecurityData.HasReadAccess,
            write: entityItem.SecurityData.HasWriteAccess,
            create: entityItem.SecurityData.HasCreateAccess,
            delete: entityItem.SecurityData.HasDeleteAccess,
            import: entityItem.SecurityData.HasImportAccess,
            export: entityItem.SecurityData.HasExportAccess,
            management: entityItem.SecurityData.HasManagementAccess,
            moduleImport: entityItem.SecurityData.hasModuleImportAccess,
            officialRoleAttributesWrite:
                entityItem.SecurityData.HasOfficialRoleAttributesWriteAccess,
            statusWrite: entityItem.SecurityData.HasEntityStatusWriteAccess,
            suggestionModeWrite:
                entityItem.SecurityData.HasSuggestionModeWriteAccess,
        };
    }
}
