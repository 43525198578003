import { StringUtil } from '@datagalaxy/core-util';
import {
    BaseMetaAttributeValue,
    TextAttributeValue,
} from '@datagalaxy/meta-attribute-domain';
import { RichTextContent } from '@datagalaxy/rich-text-utils';

export function buildRichTextValue(
    baseMetaAttributeValue: BaseMetaAttributeValue,
    value: unknown,
): TextAttributeValue {
    return {
        ...baseMetaAttributeValue,
        value: extractHtmlFromDocument(value as string),
    } as TextAttributeValue;
}

function extractHtmlFromDocument(serializedDocument: string) {
    return RichTextContent.getHTMLText(serializedDocument);
}

export function serializeRichTextValue(value: TextAttributeValue): string {
    const content = new RichTextContent();
    content.HtmlText = value.value;
    content.RawText = StringUtil.getRawTextFromHtmlString(value.value) ?? '';
    return content.toJsonString();
}
