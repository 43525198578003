import {
    BooleanAttributeValue,
    DateAttributeValue,
    MetaAttributeValue,
    NumberAttributeValue,
    TextAttributeValue,
    UnknownAttributeValue,
} from '@datagalaxy/meta-attribute-domain';
import {
    AttributeMetaInfo,
    AttributeMetaType,
} from '@datagalaxy/webclient/attribute/domain';
import { buildBaseValue } from './attribute-value-adapter/base-value';
import { buildGenericValue } from './attribute-value-adapter/generic-value';
import { buildRichTextValue } from './attribute-value-adapter/rich-text-value';
import { buildSelectValue } from './attribute-value-adapter/select-value';
import { buildMultiSelectValue } from './attribute-value-adapter/multi-select-value';
import { buildUsersValue } from './attribute-value-adapter/users-value';
import { buildHyperlinkValue } from './attribute-value-adapter/hyperlink-value';
import { buildLinkedObjectsValue } from './attribute-value-adapter/linked-objects-value';
import { buildParentValue } from './attribute-value-adapter/parent-value';
import { buildDataModelTypeValue } from './attribute-value-adapter/model-type-value';
import { buildTechnologyValue } from './attribute-value-adapter/technology-value';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

export function buildMetaAttributeValue(
    entity: EntityItem,
    ami: AttributeMetaInfo,
    value: unknown,
): MetaAttributeValue | undefined {
    const objectId = entity.ReferenceId;
    const baseMetaAttributeValue = buildBaseValue(
        objectId,
        ami.AttributePath ?? ami.AttributeKey,
    );
    switch (ami.AttributeType) {
        case AttributeMetaType.Text:
        case AttributeMetaType.MultiLineText:
            return buildGenericValue<TextAttributeValue>(
                baseMetaAttributeValue,
                value,
            );
        case AttributeMetaType.FormattedText:
            return buildRichTextValue(baseMetaAttributeValue, value);
        case AttributeMetaType.Boolean:
            return buildGenericValue<BooleanAttributeValue>(
                baseMetaAttributeValue,
                value,
            );
        case AttributeMetaType.Number:
            return buildGenericValue<NumberAttributeValue>(
                baseMetaAttributeValue,
                value,
            );
        case AttributeMetaType.Date:
        case AttributeMetaType.DateTime:
            return buildGenericValue<DateAttributeValue>(
                baseMetaAttributeValue,
                value,
            );
        case AttributeMetaType.ValueList:
            return buildSelectValue(baseMetaAttributeValue, value as string);
        case AttributeMetaType.MultiValueList:
        case AttributeMetaType.ClientTag:
        case AttributeMetaType.ManagedTag:
        case AttributeMetaType.Hierarchy:
            return buildMultiSelectValue(
                baseMetaAttributeValue,
                value as string[],
            );
        case AttributeMetaType.PersonReference:
        case AttributeMetaType.StewardUserReference:
        case AttributeMetaType.UserReference:
            return buildUsersValue(baseMetaAttributeValue, value as string[]);
        case AttributeMetaType.HtmlLink:
            return buildHyperlinkValue(baseMetaAttributeValue, value as string);
        case AttributeMetaType.EntityLogicalParent:
            return buildParentValue(baseMetaAttributeValue, value);
        case AttributeMetaType.ReferenceList:
        case AttributeMetaType.EntityLinkShortcut:
            return buildLinkedObjectsValue(baseMetaAttributeValue, value);
        case AttributeMetaType.DataTypeAndSize:
            return buildDataModelTypeValue(
                entity,
                baseMetaAttributeValue,
                value as string,
            );
        case AttributeMetaType.Technology:
            return buildTechnologyValue(
                baseMetaAttributeValue,
                value as string,
            );
        default:
            return {
                ...baseMetaAttributeValue,
                value,
            } as UnknownAttributeValue;
    }
}
